/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { RootState } from '../store';

export const currentWordSelector = (state: RootState) => state.game.currentWord;

export const errorSelector = (state: RootState) => state.game.error;

export const guessesSelector = (state: RootState) => state.game.guesses;

export const numberOfLettersSelector = (state: RootState) => state.game.numberOfLetters;

export const streakSelector = (state: RootState) => state.game.streak;

export const successSelector = (state: RootState) => state.game.success;

export const totalCompletedSelector = (state: RootState) => state.game.totalCompleted;
