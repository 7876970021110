import styled from 'styled-components';

export const WordLengthPickerWrapper = styled.div`
  // flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
  max-width: 32em;
  margin: 0 auto;
`;
