import styled from 'styled-components';

export const GameWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-medium);
  display: flex;
  flex-direction: column;
  max-width: 32em;
  overflow: hidden;
  width: calc(100vw - 32px);
  margin: 0 auto;
`;
