import type { MouseEventHandler } from 'react';

import { newGame, reset } from '../../reducers/game';
import {
  currentWordSelector,
  numberOfLettersSelector,
  streakSelector,
  totalCompletedSelector,
} from '../../selectors/game';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '../button/Button';

export const Success = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const currentWord = useAppSelector(currentWordSelector);
  const numberOfLetters = useAppSelector(numberOfLettersSelector);
  const streak = useAppSelector(streakSelector);
  const totalCompleted = useAppSelector(totalCompletedSelector);

  const onClickReset: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(reset());
  };

  const onClickNewGame: MouseEventHandler<HTMLButtonElement> = () => {
    if (numberOfLetters) {
      dispatch(newGame(numberOfLetters));
    }
  };

  return (
    <div style={{ padding: '32px', textAlign: 'center' }}>
      Congratulations! You guessed <span style={{ fontWeight: 700 }}>{currentWord?.toUpperCase()}</span>
      <br />
      <br />
      {streak > 1 ? `Winning streak of ${streak}, with ` : ''}
      {totalCompleted} game{totalCompleted > 1 ? 's' : ''} completed.
      <br />
      <br />
      <Button onClick={onClickReset} style={{ backgroundColor: '#2196f3' }}>
        Home
      </Button>
      <Button onClick={onClickNewGame} style={{ backgroundColor: '#2196f3' }}>
        New Game
      </Button>
    </div>
  );
};
