import type { MouseEventHandler } from 'react';

import { newGame, reset } from '../../reducers/game';
import { currentWordSelector, errorSelector, numberOfLettersSelector } from '../../selectors/game';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '../button/Button';

export const ErrorMessage = (): JSX.Element => {
  const currentWord = useAppSelector(currentWordSelector);
  const error = useAppSelector(errorSelector);
  const dispatch = useAppDispatch();

  const numberOfLetters = useAppSelector(numberOfLettersSelector);

  const onClickReset: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(reset());
  };

  const onClickNewGame: MouseEventHandler<HTMLButtonElement> = () => {
    if (numberOfLetters) {
      dispatch(newGame(numberOfLetters));
    }
  };

  return (
    <div style={{ padding: '32px', textAlign: 'center' }}>
      {error}
      <br />
      Word was: <span style={{ fontWeight: '700' }}>{currentWord?.toUpperCase()}</span>
      <br />
      <br />
      <Button onClick={onClickReset} style={{ backgroundColor: '#2196f3' }}>
        Home
      </Button>
      <Button onClick={onClickNewGame} style={{ backgroundColor: '#2196f3' }}>
        New Game
      </Button>
    </div>
  );
};
