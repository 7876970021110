import { colors } from '../../constants/colors';
import type { GuessLetter } from '../../reducers/game';
import { GuessStatus } from '../../reducers/game';
import { guessesSelector, numberOfLettersSelector } from '../../selectors/game';
import { useAppSelector } from '../../store';

import { GuessesRow } from './GuessesRow';
import { GuessesRowItem } from './GuessesRowItem';
import { GuessesRowLetter } from './GuessesRowLetter';
import { GuessesWrapper } from './GuessesWrapper';

const MAX_GUESSES = 6;

export const Guesses = (): JSX.Element => {
  const guesses = useAppSelector(guessesSelector);
  const numberOfLetters = useAppSelector(numberOfLettersSelector);

  const blankLetter = { letter: ' ', status: GuessStatus.INCORRECT };
  const blankLetters = Array.from({ length: numberOfLetters ?? 0 }).fill(blankLetter) as GuessLetter[];

  const blanks = numberOfLetters
    ? (Array.from({ length: MAX_GUESSES - guesses.length }).fill(blankLetters) as GuessLetter[][])
    : [];

  return (
    <GuessesWrapper>
      {[...guesses, ...blanks].map((guess, guessIndex) => {
        return (
          <GuessesRow key={`guess-${guessIndex}`}>
            {guess.map((guessLetter, guessLetterIndex) => {
              const { letter, status: letterStatus } = guessLetter;

              const { correct, error, incorrect, misplaced } = colors;
              let backgroundColor = incorrect;

              if (letterStatus === GuessStatus.CORRECT) {
                backgroundColor = correct;
              } else if (letterStatus === GuessStatus.MISPLACED) {
                backgroundColor = misplaced;
              } else if (letterStatus === GuessStatus.INCORRECT) {
                backgroundColor = incorrect;
              } else {
                backgroundColor = error;
              }

              return (
                <GuessesRowItem key={`guess-${guessIndex}-letter-${guessLetterIndex}`}>
                  <GuessesRowLetter style={{ backgroundColor }}>{letter}</GuessesRowLetter>
                </GuessesRowItem>
              );
            })}
          </GuessesRow>
        );
      })}
    </GuessesWrapper>
  );
};
