import styled from 'styled-components';

export const GuessesRowLetter = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
`;
