import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import JSConfetti from 'js-confetti';
import { sample } from 'lodash';

import dictionaryWords from '../data/dictionary.json';
import possibleWords from '../data/words.json';

const jsConfetti = new JSConfetti();

/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum NumberOfLetters {
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export enum GuessStatus {
  CORRECT = 'CORRECT',
  ERROR = 'ERROR',
  INCORRECT = 'INCORRECT',
  MISPLACED = 'MISPLACED',
}

export type GuessLetter = {
  letter: string;
  status: GuessStatus;
};

type GameState = {
  currentWord: string | null;
  error: string | null;
  guesses: GuessLetter[][];
  numberOfLetters: NumberOfLetters | null;
  streak: number;
  success: boolean;
  totalCompleted: number;
};

const initialState: GameState = {
  currentWord: null,
  error: null,
  guesses: [],
  numberOfLetters: null,
  streak: 0,
  success: false,
  totalCompleted: 0,
};

const MAX_GUESSES = 6;

const gameSlice = createSlice({
  initialState,
  name: 'game',
  reducers: {
    giveUp(state) {
      state.error = 'You gave up :(';
    },
    guess(state, action: PayloadAction<string>) {
      const guessWord = action.payload.trim().toLowerCase();

      const { numberOfLetters } = state;

      if (state.currentWord === null || numberOfLetters === null) {
        state.error = `No game in progress`;

        return;
      }

      const { [numberOfLetters]: words } = dictionaryWords;

      const wordExists = words.includes(guessWord);

      const guessLetters = guessWord.split('');
      const wordLetters = state.currentWord.split('');

      const unguessedLetters = wordLetters.filter((letter, index) => {
        return guessLetters[index] !== letter;
      });

      const newGuess = guessLetters.map((letter, index) => {
        if (!wordExists || (state.guesses.length === MAX_GUESSES - 1 && guessWord !== state.currentWord)) {
          return {
            letter,
            status: GuessStatus.ERROR,
          };
        }

        if (state.currentWord?.[index] === letter) {
          return {
            letter,
            status: GuessStatus.CORRECT,
          };
        }

        if (unguessedLetters.includes(letter)) {
          const indexToRemove = unguessedLetters.indexOf(letter);
          unguessedLetters.splice(indexToRemove, 1);

          return {
            letter,
            status: GuessStatus.MISPLACED,
          };
        }

        return {
          letter,
          status: GuessStatus.INCORRECT,
        };
      });

      state.error = null;

      if (newGuess.every((guessLetter) => guessLetter.status === GuessStatus.CORRECT)) {
        state.success = true;
        void jsConfetti.addConfetti({
          emojis: ['✨'],
        });
        state.streak += 1;
        state.totalCompleted += 1;
      } else if (!wordExists) {
        state.error = 'Not a word!';
        state.streak = 0;
      } else if (state.guesses.length === MAX_GUESSES - 1) {
        state.error = 'Out of guesses!';
        state.streak = 0;
      }

      if (state.guesses.length === 1 && state.guesses[0]?.[1]?.letter === ' ') {
        state.guesses = [newGuess];
      } else {
        state.guesses.push(newGuess);
      }
    },
    newGame(state, action: PayloadAction<NumberOfLetters>) {
      const { payload: numberOfLetters } = action;
      const { [numberOfLetters]: words } = possibleWords;
      const word = sample(words) ?? null;

      if (word === null) {
        throw new Error('Word choice failed');
      }

      const initialGuess = word.split('').map((letter, index) => ({
        letter: index === 0 ? letter : ' ',
        status: index === 0 ? GuessStatus.CORRECT : GuessStatus.INCORRECT,
      }));

      state.currentWord = word;
      state.error = null;
      state.guesses = [initialGuess];
      state.numberOfLetters = numberOfLetters;
      state.success = false;
    },
    reset(state) {
      state.currentWord = null;
      state.error = null;
      state.guesses = [];
      state.numberOfLetters = null;
      state.streak = 0;
      state.success = false;
    },
  },
});

const { actions, reducer } = gameSlice;

export const { giveUp, guess, newGame, reset } = actions;

export default reducer;
