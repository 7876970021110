import type { MouseEventHandler } from 'react';

import { newGame, NumberOfLetters } from '../../reducers/game';
import { useAppDispatch } from '../../store';

import { WordLengthPickerButton } from './WordLengthPickerButton';
import { WordLengthPickerWrapper } from './WordLengthPickerWrapper';

export const WordLengthPicker = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const onClickLength4: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame(NumberOfLetters.FOUR));
  };

  const onClickLength5: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame(NumberOfLetters.FIVE));
  };

  const onClickLength6: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame(NumberOfLetters.SIX));
  };

  const onClickLength7: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame(NumberOfLetters.SEVEN));
  };

  const onClickLength8: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame(NumberOfLetters.EIGHT));
  };

  return (
    <WordLengthPickerWrapper>
      <WordLengthPickerButton onClick={onClickLength4} style={{ backgroundColor: '#43aa8b' }}>
        4
      </WordLengthPickerButton>
      <WordLengthPickerButton onClick={onClickLength5} style={{ backgroundColor: '#90be6d' }}>
        5
      </WordLengthPickerButton>
      <WordLengthPickerButton onClick={onClickLength6} style={{ backgroundColor: '#f9c74f' }}>
        6
      </WordLengthPickerButton>
      <WordLengthPickerButton onClick={onClickLength7} style={{ backgroundColor: '#f8961e' }}>
        7
      </WordLengthPickerButton>
      <WordLengthPickerButton onClick={onClickLength8} style={{ backgroundColor: '#f3722c' }}>
        8
      </WordLengthPickerButton>
    </WordLengthPickerWrapper>
  );
};
