import styled from 'styled-components';

export const WelcomeWrapper = styled.div`
  //   background-color: #ffffff;
  border-radius: 8px;
  //   box-shadow: var(--shadow-elevation-medium);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 32em;
  overflow: hidden;
  width: calc(100vw - 32px);
  min-height: 100%;

  //   @media screen and (min-width: 481px) {
  //     width: calc(100vw - 32px);
  //     margin: 16px auto;
  //     min-height: calc(100vh - 32px);
  //   }
`;
