import { Provider } from 'react-redux';

import { Game } from './components/game/Game';
import { store } from './store';

// import { Words } from './components/game/Words';

export const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      {/* <Words /> */}
      <Game />
    </Provider>
  );
};
