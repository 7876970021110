import { WelcomeBannerInstructions } from './WelcomeBannerInstructions';
import { WelcomeBannerTitle } from './WelcomeBannerTitle';

export const WelcomeBanner = (): JSX.Element => {
  return (
    <>
      <WelcomeBannerTitle>
        <span style={{ color: '#43aa8b', display: 'block', marginLeft: '-16px' }}>LINGO</span>
        <span style={{ color: '#90be6d', display: 'block' }}>LINGO</span>
        <span style={{ color: '#f9c74f', display: 'block', marginRight: '-16px' }}>LINGO</span>
        <span style={{ display: 'block', fontSize: 16, fontWeight: 300, marginTop: 32 }}> Free word guessing game</span>
      </WelcomeBannerTitle>
      <WelcomeBannerInstructions>How many letters would you like to solve?</WelcomeBannerInstructions>
    </>
  );
};
