import styled from 'styled-components';

export const WordLengthPickerButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 8px;
  margin: 4px;
  color: #ffffff;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  border-radius: 8px;
  font-size: 24px;
  flex: 1;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 0;

  @media screen and (min-width: 481px) {
    padding-top: 32px 0;
  }
`;
