import styled from 'styled-components';

export const WelcomeBannerTitle = styled.h1`
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 32px;

  &:first-child {
    margin-top: 32px;

    @media screen and (min-width: 481px) {
      margin-top: 64px;
    }
  }
`;
