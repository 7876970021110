import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { guess } from '../../reducers/game';
import { currentWordSelector, numberOfLettersSelector } from '../../selectors/game';
import { useAppDispatch, useAppSelector } from '../../store';

const Input = styled.input`
  margin: 16px;
  padding: 8px;
  font-size: 18px;
  border: none;
  background-color: #57759033;
  color: #212121;
  font-family: 'Nunito', sans-serif;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
`;

export const GuessInput = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const numberOfLetters = useAppSelector(numberOfLettersSelector);
  const currentWord = useAppSelector(currentWordSelector);

  const [value, setValue] = useState('');

  const onChange: ChangeEventHandler<HTMLInputElement> = (changeEvent) => {
    const newValue = changeEvent.target.value.trim();

    if (numberOfLetters === newValue.length) {
      dispatch(guess(newValue));
      setValue('');
    } else {
      setValue(newValue);
    }
  };

  return (
    <>
      <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'stretch' }}>
        <Input autoFocus type="text" onChange={onChange} value={value} placeholder="Type here" />
      </div>
      <p style={{ margin: 4, textAlign: 'center' }}>
        {numberOfLetters} letter word, starting with{' '}
        <span style={{ fontWeight: 700 }}>{currentWord?.[0]?.toUpperCase() ?? '?'}</span>
      </p>
    </>
  );
};
