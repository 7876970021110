import type { MouseEventHandler } from 'react';

import { giveUp } from '../../reducers/game';
import { currentWordSelector, errorSelector, successSelector } from '../../selectors/game';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '../button/Button';
import { Changelog } from '../changelog/Changelog';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { GuessInput } from '../guess-input/GuessInput';
import { Guesses } from '../guesses/Guesses';
import { Success } from '../success/Success';
import { WelcomeBanner } from '../welcome-banner/WelcomeBanner';
import { WordLengthPicker } from '../word-length-picker/WordLengthPicker';

import { GameWrapper } from './GameWrapper';
import { WelcomeWrapper } from './WelcomeWrapper';

export const Game = (): JSX.Element => {
  const currentWord = useAppSelector(currentWordSelector);
  const error = useAppSelector(errorSelector);
  const success = useAppSelector(successSelector);

  const dispatch = useAppDispatch();

  const onClickGiveUp: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(giveUp());
  };

  if (currentWord === null) {
    return (
      <WelcomeWrapper>
        <WelcomeBanner />
        <WordLengthPicker />
        <Changelog />
        <div style={{ flex: 1 }} />
        <p style={{ fontSize: '12px', fontWeight: '300', paddingBottom: 8, textAlign: 'center' }}>
          &copy; 2022&mdash;{new Date().getFullYear()} Michael Mason -{' '}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfPOa_t3hEP8C8nmCBmt2zERINM1tnriPPOIxBsi-Vw0pWgFw/viewform?usp=sf_link"
            target="_blank"
            style={{ color: '#f3722c', textDecoration: 'none' }}
          >
            Feedback 🙉
          </a>{' '}
          -{' '}
          <a
            href="https://buymeacoffee.com/mjmasn"
            target="_blank"
            style={{ color: '#6f4e37', textDecoration: 'none' }}
          >
            Buy me a coffee ☕
          </a>
        </p>
      </WelcomeWrapper>
    );
  }

  return (
    <>
      <div style={{ height: '16px' }} />
      <GameWrapper>
        <Guesses />
        {error === null ? null : <ErrorMessage />}
        {success ? <Success /> : null}
        {error === null && !success ? (
          <>
            <GuessInput />
            <br />
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '16px',
              }}
            >
              <Button onClick={onClickGiveUp} style={{ backgroundColor: '#2196f3', flex: 0 }}>
                Give Up?
              </Button>
            </div>
          </>
        ) : null}
      </GameWrapper>
      <div style={{ height: '16px' }} />
    </>
  );
};
