import { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../constants/colors';

const Wrapper = styled.div`
  margin: 32px 16px 16px 16px;
`;
const ChangelogTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: #212121;
`;
const ReleaseTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  margin: 16px 0 0 0;
  color: #212121;
`;
const ReleaseDate = styled.p`
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  margin: 0;
  color: #666;
`;
const ReleaseList = styled.ul`
  font-size: 12px;
  font-weight: 300;
  margin: 8px 0;
  padding: 0;
`;
const ReleaseBullet = styled.li`
  font-size: 12px;
  font-weight: 300;
  margin: 0 0 0 20px;
  padding: 0;
  color: #212121;
`;
const TableWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  margin: 4px 0;
`;
const TableScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;
const Table = styled.table`
  border-collapse: collapse;
  min-width: 100%;
`;
const TableHeader = styled.th`
  border-collapse: collapse;
  background-color: #e7e7e7;
  padding: 8px;
`;
const TableCell = styled.td`
  border-collapse: collapse;
  background-color: #ffffff;
  padding: 8px;
  white-space: nowrap;
`;
const GuessCorrect = styled.span`
  background-color: ${colors.correct};
  color: #fff;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  margin: 0 1px;
`;
const GuessIncorrect = styled.span`
  background-color: ${colors.incorrect};
  color: #fff;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  margin: 0 1px;
`;
const GuessMisplaced = styled.span`
  background-color: ${colors.misplaced};
  color: #fff;
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  margin: 0 1px;
`;

export const Changelog = (): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const onClickTitle = (): void => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Wrapper>
      <ChangelogTitle onClick={onClickTitle}>Changelog</ChangelogTitle>
      {expanded ? (
        <>
          <ReleaseTitle>Feedback heard</ReleaseTitle>
          <ReleaseDate>28 Jun 2023</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>
              Based on user feedback, removed a number of past tense, present participle, and plural words
            </ReleaseBullet>
          </ReleaseList>
          <ReleaseTitle>Woo! 2600* unique visitors in Month One</ReleaseTitle>
          <ReleaseDate>5 Feb 2022</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>*including plenty of bots I'm sure, Netlify analytics are #basic</ReleaseBullet>
            <ReleaseBullet>Public changelog for your reading pleasure</ReleaseBullet>
            <ReleaseBullet>
              Improved hinting for incorrectly placed letters where the guess contains that letter more times than the
              current word
              <TableWrapper>
                <TableScrollWrapper>
                  <Table>
                    <tbody>
                      <tr>
                        <TableHeader>Guess</TableHeader>
                        <TableCell>HELLO</TableCell>
                        <TableCell>SPOOL</TableCell>
                        <TableCell>EMERGES</TableCell>
                      </tr>
                      <tr>
                        <TableHeader>Word</TableHeader>
                        <TableCell>LEMUR</TableCell>
                        <TableCell>TUTOR</TableCell>
                        <TableCell>BEATERS</TableCell>
                      </tr>
                      <tr>
                        <TableHeader>Before</TableHeader>
                        <TableCell>
                          <GuessIncorrect>H</GuessIncorrect>
                          <GuessCorrect>E</GuessCorrect>
                          <GuessMisplaced>L</GuessMisplaced>
                          <GuessMisplaced>L</GuessMisplaced>
                          <GuessIncorrect>O</GuessIncorrect>
                        </TableCell>
                        <TableCell>
                          <GuessIncorrect>S</GuessIncorrect>
                          <GuessIncorrect>P</GuessIncorrect>
                          <GuessIncorrect>O</GuessIncorrect>
                          <GuessCorrect>O</GuessCorrect>
                          <GuessIncorrect>L</GuessIncorrect>
                        </TableCell>
                        <TableCell>
                          <GuessMisplaced>E</GuessMisplaced>
                          <GuessIncorrect>M</GuessIncorrect>
                          <GuessMisplaced>E</GuessMisplaced>
                          <GuessMisplaced>R</GuessMisplaced>
                          <GuessIncorrect>G</GuessIncorrect>
                          <GuessMisplaced>E</GuessMisplaced>
                          <GuessCorrect>S</GuessCorrect>
                        </TableCell>
                      </tr>
                      <tr>
                        <TableHeader>After</TableHeader>
                        <TableCell>
                          <GuessIncorrect>H</GuessIncorrect>
                          <GuessCorrect>E</GuessCorrect>
                          <GuessMisplaced>L</GuessMisplaced>
                          <GuessIncorrect>L</GuessIncorrect>
                          <GuessIncorrect>O</GuessIncorrect>
                        </TableCell>
                        <TableCell>
                          <GuessIncorrect>S</GuessIncorrect>
                          <GuessIncorrect>P</GuessIncorrect>
                          <GuessIncorrect>O</GuessIncorrect>
                          <GuessCorrect>O</GuessCorrect>
                          <GuessIncorrect>L</GuessIncorrect>
                        </TableCell>
                        <TableCell>
                          <GuessMisplaced>E</GuessMisplaced>
                          <GuessIncorrect>M</GuessIncorrect>
                          <GuessMisplaced>E</GuessMisplaced>
                          <GuessMisplaced>R</GuessMisplaced>
                          <GuessIncorrect>G</GuessIncorrect>
                          <GuessIncorrect>E</GuessIncorrect>
                          <GuessCorrect>S</GuessCorrect>
                        </TableCell>
                      </tr>
                    </tbody>
                  </Table>
                </TableScrollWrapper>
              </TableWrapper>
            </ReleaseBullet>
            <ReleaseBullet>Various other improvements and fixes</ReleaseBullet>
          </ReleaseList>
          <ReleaseTitle>Room for a little one?</ReleaseTitle>
          <ReleaseDate>8 Jan 2022</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>Favicon!</ReleaseBullet>
            <ReleaseBullet>Minor styling improvements</ReleaseBullet>
          </ReleaseList>
          <ReleaseTitle>Give yourself a shiny</ReleaseTitle>
          <ReleaseDate>7 Jan 2022</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>Shiny confetti when you win</ReleaseBullet>
            <ReleaseBullet>Track win streaks for the current session</ReleaseBullet>
            <ReleaseBullet>
              Shiny social media previews when sharing the{' '}
              <a href="https://www.lingolingolingo.com">https://www.lingolingolingo.com</a> link
            </ReleaseBullet>
            <ReleaseBullet>Improved meta tags for SEO</ReleaseBullet>
            <ReleaseBullet>Now you can send feedback and/or buy me a coffee if you feel like it</ReleaseBullet>
          </ReleaseList>
          <ReleaseTitle>"What is Tabu, besides this lovely Indian actress?"</ReleaseTitle>
          <ReleaseDate>6 Jan 2022</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>Improved word list with no swearing and fewer obscure words / spellings</ReleaseBullet>
            <ReleaseBullet>
              A separate full dictionary so you will no longer lose the game when guessing a valid English word that
              isn't in the main word list
            </ReleaseBullet>
            <ReleaseBullet>6 letters not enough? Try 7 and 8 letter words!</ReleaseBullet>
            <ReleaseBullet>Various other improvements and fixes</ReleaseBullet>
          </ReleaseList>
          <ReleaseTitle>Friends and family</ReleaseTitle>
          <ReleaseDate>5 Jan 2022</ReleaseDate>
          <ReleaseList>
            <ReleaseBullet>The initial MVP release, whipped up in around 3 hours</ReleaseBullet>
          </ReleaseList>
        </>
      ) : null}
    </Wrapper>
  );
};
